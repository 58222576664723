/* --------------------------------------------------
	18.0 Blog
-------------------------------------------------- */



.blog-pagination {

	.pagination {
		margin: 0;

		li {

			a {
				border: none;
				background: none;
				color: $gray-light;
				font-size: 1.2em;

				&:hover {
					border: none;
					color: $dark;
				}
			}
		}
	}
}

.blog-post-nav {
	padding-top: $ws-m;
	padding-bottom: $ws-s;

	.prev-btn,
	.next-btn {
		@extend h6;
		display: inline-block;
		color: $gray-light;

		&:hover {
			color: $dark;
		}
	}

	.prev-btn {
		float: left;
		padding-left: 15px;
	}

	.next-btn {
		float: right;
		padding-right: 15px;
	}
}



/* ---- 18.1 Blog Columns ---- */
.blog-columns {

	.blog-post {
		border-top: 1px solid $lighter;
		background: $lighter;
		box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
		transition: all .3s;

		&:hover {
			transform: translateY(-10px);
			box-shadow: 0 22px 43px rgba(0, 0, 0, 0.15);
		}

		.post-img {
			display: block;

			img {
				max-width: 100%;
				height: auto;
				transition: opacity .5s;
			}

			&:hover {

				img {
					opacity: .8;
				}
			}
		} // .post-img

		.bp-content {
			padding: 40px 50px 50px 50px;

			.post-meta {

				.post-date,
				.post-comments {

					i {
						display: inline-block;
						padding-right: 9px;
						font-size: 18px;
						vertical-align: middle;
						color: #ccc;
						transition: color .3s;
					}

					span {
						display: inline-block;
						padding-left: 10px;
						border-left: 1px solid $light;
						font-family: $alt-heading-font;
						font-size: .75em;
						letter-spacing: 2px;
						text-transform: uppercase;
						vertical-align: middle;
						color: $gray-light;
						transition: color .3s;
					}

					&:hover {

						i,
						span {
							color: $dark;
						}
					}
				}

				.post-comments {
					float: right;
				}
			} // .post-meta

			.post-title {
				display: block;
				padding: 25px 0 20px 0;
				color: $dark;

				h3,
				h4,
				h5 {
					margin: 0;
				}
			} // .post-title

			p {
				margin-bottom: 30px;
			}
		} // .bp-content
	} // .blog-post
} // .blog-columns



.blog-preview {

	.blog-post {
		background: #fff;
	}
}



/* ---- 18.2 Blog Masonry ---- */
.blog-masonry {

	.blog-post {
		margin-bottom: 30px;
	}
}



/* ---- 18.3 Blog Classic ---- */
.blog-classic {
	@extend .blog-columns;

	.blog-post {
		margin-bottom: $ws-m;
	}
}



/* ---- 18.4 Blog Post Single ---- */
.blog-post-single {
	margin-bottom: $ws-m;

	.post-img {
		margin-bottom: 20px;
	}

	.post-meta {

		.post-date,
		.post-comments {

			i {
				display: inline-block;
				padding-right: 9px;
				font-size: 18px;
				vertical-align: middle;
				color: #ccc;
				transition: color .3s;
			}

			span {
				display: inline-block;
				padding-left: 10px;
				border-left: 1px solid $light;
				font-family: $alt-heading-font;
				font-size: .75em;
				letter-spacing: 2px;
				text-transform: uppercase;
				vertical-align: middle;
				color: $gray-light;
				transition: color .3s;
			}

			&:hover {

				i,
				span {
					color: $dark;
				}
			}
		}

		.post-comments {
			float: right;
		}
	} // .post-meta

	.post-title {
		margin: 0;
		padding-top: 30px;
	}

	.blog-post-content {
		margin-top: $ws-s;
		margin-bottom: $ws-s;

		p {
			margin-bottom: 0;
		}
		
		.left-blog-quote {
			margin: 20px 30px 20px -50px;
		}

		.right-blog-quote {
			margin: 20px -50px 20px 30px;
		}
	}
}



/* ---- 18.5 Sidebar ---- */
.sidebar {

	.header-widget {
		padding-bottom: 18px;
		margin-bottom: 30px;
		border-bottom: 1px solid $hr-color;
	}

	.recent-posts-widget {

		.widget-item {
			padding-bottom: 15px;

			&:last-child {
				padding-bottom: 0;
			}

			h6 {
				margin-top: 0;
				margin-bottom: 0;
				color: $gray;
			}

			span {
				font-size: .9em;
				letter-spacing: .6px;
				color: #ccc;

				a {
					color: #ccc;

					&:hover {
						color: $dark;
					}
				}
			}
		}
	} // .recent-posts-widget

	.categories-widget {

		.widget-item {

			a {
				padding-bottom: 5px;
				margin-bottom: 5px;
				font-size: .9em;
				letter-spacing: .3px;
				color: $body-text-color;
				border-bottom: 1px solid rgba(17,17,17, 0);
				transition: all .3s;

				span {
					color: #ccc;
				}

				&:hover {
					padding-bottom: 3px;
					border-bottom: 1px solid rgba(17,17,17, 1);
					color: $dark;
				}
			}
		}
	} // .categories-widget

	.tags-widget {

		.tag-list {
			padding: 0;
			margin: 0;

			li {
				list-style: none;
				display: inline-block;

				a {
					@extend h6;
					display: block;
					padding: 0 10px;
					margin-bottom: 5px;
					border: 1px solid #ccc;
					font-family: $alt-heading-font;
					color: $gray-light;
					transition: all .5s;

					&:hover {
						border-color: $gray;
						color: $dark;
					}
				}
			}
		}
	} // .tags-widget

	.search-widget {

		.form-group {
			position: relative;

			.inside-input-btn {
				display: block;
				position: absolute;
				width: 20px;
				height: 20px;
				top: 4px;
				right: 20px;
				background: none;
				border: none;
				font-size: 18px;
				color: #ccc;
				transition: color .5s;

				&:hover {
					color: $dark;
				}

				&:focus,
				&:visited {
					outline: none;
				}
			}
		}
	} // .searh-widget

	.comments-widget {

		.widget-item {

			span {
				font-size: .9em;
				color: #ccc;

				a {
					color: #ccc;

					&:hover {
						color: $dark;
					}
				}

				.widget-comm-title {
					@extend h5;
					font-family: $alt-heading-font;
					letter-spacing: 1.5px;
					color: $gray;
				}
			}
		}
	} // .comments-widget
} // .sidebar



/* ---- 18.6 Comments ---- */
.blog-post-comments {

	.blog-section-title {
		padding-bottom: 18px;
		margin-bottom: 30px;
		border-bottom: 1px solid $hr-color;
	}

	.bp-comment {
		margin-bottom: 30px;

		.comment-avatar {
			display: inline-block;
			position: relative;
			width: 60px;
			height: 60px;
			background: $light-bg;

			i {
				display: block;
				position: absolute;
				width: 20px;
				height: 20px;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				font-size: 22px;
				color: #ccc;
				margin: auto;
			}
		}

		.comment-info {
			display: inline-block;
			margin-left: 30px;
			margin-top: -40px;
			vertical-align: text-top;

			.comment-name {
				display: inline-block;
				color: $gray-light;
			}

			.comment-time {
				display: inline-block;
				margin-left: 30px;
				margin-right: 30px;
				text-transform: uppercase;
				font-family: $body-font;
				font-size: .8em;
				letter-spacing: 1.5px;
				color: #ccc;
			}

			.comment-replay-btn {
				display: inline-block;
				padding: 5px 20px;
				background: none;
				border: 1px solid #ccc;
				text-transform: uppercase;
				font-size: .8em;
				line-height: 1em;
				letter-spacing: 2px;
				color: $gray-light;
				transition: all .3s;

				&:hover {
					color: $light;
					border-color: $dark;
					background: $dark;
				}
			}
		} // .comment-info

		.comment-content {
			margin-top: -20px;
			margin-left: 90px;
			padding-bottom: 30px;
			border-bottom: 1px solid $light;
		}
	} // .bp-comment

	.bp-comment-reply {
		@extend .bp-comment;
		margin-left: 90px;
	}

	.comment-form {
		margin-top: $ws-m;
	}
} // .blog-post-comments 