/* --------------------------------------------------
	10.0 Counters
-------------------------------------------------- */



/* ---- 10.1 Circle Counters ---- */
.circles-counters {
	position: relative;
	background: #ccc url(http://placehold.it/2440x1578);
	background-size: cover;
	background-attachment: fixed;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(17,17,17, .55);
	}

	.circle-item {
		padding-top: 100px;
		padding-bottom: 100px;
		text-align: center;
		
		.chart {
			position: relative;

			.circle-icon {
				display: block;
				position: absolute;
				top: 43px;
				left: 0;
				right: 0;
				margin: auto;

				font-size: 64px;
				color: $light;
			}
		}

		.circle-text {
			@extend h6;
			color: $light;
		}
	}
} // .circles-counters-2

.circles-counters-dark-bg {
	@extend .circles-counters;
	background: $dark;
}



/* ---- 10.2 Counters - Numbers ---- */
.number-counters {
	position: relative;
	background: #ccc url(http://placehold.it/2102x1578);
	background-size: cover;
	background-position: 50%;
	background-attachment: fixed;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(17,17,17, .65);
	}

	.count-wrapper {
		padding-top: 110px;
		padding-bottom: 100px;

		.count-item {
			display: block;
			text-align: center;

			.count-nbr {
				display: block;
				margin-bottom: 30px;
				font-family: $alt-heading-font;
				font-size: 65px;
				font-weight: lighter;
				color: $light;
			}

			.count-text {
				@extend h6;
				color: $light;
			}
		}
	}
} // .number-counters

.number-counters-dark-bg {
	@extend .number-counters;
	background: $dark;
}