/* --------------------------------------------------
	2.0 Buttons
-------------------------------------------------- */



/* ---- 2.1 Defualt Buttons ---- */
.btn {
	position: relative;
	display: inline-block;
	padding: $btn-padding;
	background: $dark;
	color: $light;
	font-family: $btn-font;
	text-transform: uppercase;
	letter-spacing: $btn-letter-spaceing;
	font-size: $btn-font-size;
	line-height: $btn-line-height;
	border-radius: $btn-rounded;
	transition: all $btn-trans-time;

	&:visited {
		color: $light;
		background: $dark;

		&:hover {
			color: $light;
			background: lighten($dark, 18%);
		}
	}

	&:hover {
		color: $light;
		background: lighten($dark, 18%);
	}

	&:focus {
		color: $light;
		outline: none;
	}
} // btn;



/* ---- 2.2 Ghost Buttons ---- */
.btn-ghost {
	@extend .btn;
	background: none;
	color: $dark;
	border: 2px solid $dark;
	overflow: hidden;
	z-index: 1;

	&:after {
		content: '';
		position: absolute;
		background: $dark;
		left: 0;
		top: 100%;
		width: 100%;
		height: 100%;
		transition: top .3s;
		z-index: -1;
	}

	&:visited,
	&:focus {
		color: $dark;
		text-decoration: none;
	}

	&:hover {
		color: $light;
		background: none;

		&:visited {
			color: $light;
			text-decoration: none;
		}

		&:after {
			top: 0;
		}
	}
}

input[class="btn-ghost"] {

	&:hover {
		color: $light;
		background: $dark;
	}

	&:after {
		content: none;
	}
}



/* ---- 2.3 Buttons Rounded ---- */
.btn-round {
	border-radius: 25px;
}



/* ---- 2.4 Text Buttons ---- */
.btn-text {
	@extend .btn;
	background: none;
	border: 2px solid rgba(17,17,17, 0);
	color: $gray;

	&:visited {
		color: $gray;
		background: none;

		&:hover {
			color: $dark;
			background: none;
			border: 2px solid rgba(17,17,17, 1);
		}
	}

	&:hover {
		color: $dark;
		background: none;
		border: 2px solid rgba(17,17,17, 1);
	}

	&:focus {
		color: $dark;
		outline: none;
		text-decoration: none;
	}
}



/* ---- 2.5 Button Sizes ---- */
.btn-large {
	padding: 10px 54px;
	font-size: $btn-font-size + .2em;
	letter-spacing: 3px;
}

.btn-small {
	padding: 6px 33px;
	font-size: $btn-font-size - .15em;
}



/* ---- 2.6 Light Buttons ---- */
.btn-light {
	@extend .btn;

	color: $dark;
	background: $light;

	&:visited {
		color: $dark;
		background: $light;

		&:hover {
			color: $dark;
			background: darken($light, 18%);
		}
	}

	&:hover {
		color: $dark;
		background: darken($light, 18%);
	}

	&:focus {
		color: $dark;
	}
}

.btn-ghost-light {
	@extend .btn-ghost;
	z-index: 1;
	border-color: $light;
	color: $light;

	&:after {
		background: $light;
	}

	&:hover {
		color: $dark;

		&:visited {
			color: $dark;
		}
	}

	&:visited {
		color: $light;
	}
}

input[class="btn-ghost-light"] {
	z-index: 1;

	&:hover {
		z-index: 1;
		color: $dark;
		background: $light;
	}
}

.btn-text-light {
	@extend .btn-text;
	border: 2px solid rgba(235,235,235, 0);

	&:visited {

		&:hover {
			color: $light;
			border: 2px solid rgba(235,235,235, 1);
		}
	}

	&:hover {
		color: $light;
		border: 2px solid rgba(235,235,235, 1);
	}

	&:focus {
		color: $light;
	}
}



/* ---- 2.7 Pagination ---- */
.pagination {

	li {

		a {
			border-radius: 0 !important;
			border: 2px solid rgba(17,17,17, 0);
			height: 42px;
			width: 42px;
			line-height: 1.9em;
			font-family: $heading-font;
			color: $gray;

			i {
				padding-top: 7px;
				display: block;
			}

			&:focus,
			&:visited {
				background: none;
				border: 2px solid rgba(17,17,17, 0);
			}

			&:hover {
				color: $dark;
				background: none;
				border: 2px solid rgba(17,17,17, 1);
			}
		}
	}

	.active {

		a {
			border: 2px solid rgba(17,17,17, 1);
			background: none;
			color: $dark;

			&:hover {
				background: none;
				border-color: $dark;
				color: $dark;
			}
		}
	}
} // .pagination



/* ---- 2.7 PAGE - Buttons ---- */
.btn-example {
	padding-top: $ws-m;
	padding-bottom: $ws-m;
	text-align: center;
}